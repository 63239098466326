<script>
import concierges from "@/data/concierges"

export default {
  name: 'ConciergeChoice',
  data() {
    return {
      concierges: concierges.slice(1),
      currentConciergeNumber: 6,
      ROOT_IMAGE_URL: process.env.VUE_APP_IMAGE_ROOT,
      isModalOpen: false,
    }
  },
  methods:{
    next: function(is_start, q_number, a_number, finished, next_number){
        this.$emit('catchMessage', is_start, q_number, a_number, finished, next_number);
    },
    // コンシェルジュの選択
    selectConcierge(conciergeName) {
      console.log(conciergeName)
      switch(conciergeName){
        case 'maya':
          return this.currentConciergeNumber = 0
        case 'manami':
          return this.currentConciergeNumber = 1
        case 'eri':
          return this.currentConciergeNumber = 2
        case 'ayako':
          return this.currentConciergeNumber = 3
        case 'nico':
          return this.currentConciergeNumber = 4
        case 'peki':
          return this.currentConciergeNumber = 5
        case 'kazunosuke':
          return this.currentConciergeNumber = 6
      }
    },
    modalOpen(){
      this.isModalOpen=true
    },
    modalClose(e) {
      if(e.target.className=='concierge_modal'||e.target.className=='concierge_modal_close'){
        this.isModalOpen = false
      }
    },
    idToAnswerMapper(conciergeId){
      // conciergeIdをサーバー側のanswerに合わせる
      const mapper = {2: 0, 3: 1, 4: 3, 5: 4, 6: 5, 7: 2, 8: 6}
      return mapper[conciergeId.toString()]
    }
  }
}
</script>

<template>
  <section class="flow_skincheck_1">
    <div class="flow_skincheck_1_container">
      <div class="question">
        <p>Q</p>
        <h1>{{ $t('conciergeChoiceQuestion') }}</h1>
      </div>
      <p>
        {{ $t('conciergeChoiceDesc') }}
      </p>
      <section class="concierge_select_container">
        <div
          v-for="concierge in concierges"
          :key="concierge.id"
          class="concierge_select_btn"
          @click='selectConcierge(concierge.name_lower)'
        >
          <img
            class="concierge_select_btn_img"
            :class="[currentConciergeNumber === concierge.id-2 ? 'selected_concierge_icon' : '']"
            :src="
              ROOT_IMAGE_URL
                + '/face-icon/'
                + concierge.name_lower
                + '.png'
            "
          >
          <p
            :class="[currentConciergeNumber === concierge.id-2 ? 'selected_concierge_name' : '']"
          >
            {{ concierge.name }}
          </p>
        </div>
      </section>
      <section class="concierge_detail_container">
        <div
          v-for="concierge in concierges"
          :key="concierge.id"
        >
          <div
            v-show="currentConciergeNumber === concierge.id-2"
          >
            <div class="concierge_img_container">
              <img
                class="concierge_img"
                :src="
                  ROOT_IMAGE_URL
                    + '/face-icon/'
                    + concierge.name_lower
                    + '.png'
                "
              >
            </div>
            <h2>
              {{ concierge.name }}
            </h2>
            <!-- use v-if to show the profile by language -->
            <div class="concierge_profile">
              <p>{{ concierge.short_profile[$i18n.locale] }}</p>
            </div>
            <div class="profile_btn_container">
              <p
                class="profile_btn"
                @click="modalOpen"
              >
                {{ $t('conciergeSeeProfile') }}
              </p>
            </div>
            <section
              v-if="isModalOpen"
              class="concierge_modal"
              @click="modalClose($event)"
            >
              <div class="concierge_modal_container">
                <div class="concierge_modal_inner">
                  <button
                    class="concierge_modal_close"
                    @click="modalClose($event)"
                  >
                    <div class="concierge_modal_close_container">
                      <span class="concierge_modal_close_border_top" />
                      <span class="concierge_modal_close_border_bottom" />
                    </div>
                  </button>
                  <img
                    class="modal_faceIcon"
                    :src="
                      ROOT_IMAGE_URL
                        + '/face-icon/'
                        + concierge.name_lower
                        + '.png'
                    "
                  >
                  <h3>{{ concierge.name }}</h3>
                  <!-- use v-if to show the profile by language -->
                  <div
                    class="profile_content_container"
                    v-html="concierge.mid_profile[$i18n.locale]"
                  />
                </div>
                <div class="profile_btn_container">
                  <router-link
                    :to="{
                      name: 'Concierges',
                      params: {
                        'incomingGenre': 'skincare',
                        'incomingConcierge': concierge.name_lower
                      },
                    }"
                    class="profile_btn"
                  >
                    {{ $t('conciergeSeeProfileDetails') }}
                  </router-link>
                </div>
              </div>
            </section>
            <a
              class="selectConcierge_btn"
              @click="next(false, 3, idToAnswerMapper(concierge.id), false, 4)"
            >
              {{ $t('conciergeSelect') }}
            </a>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<style scoped>
h2{
  font-size: 20px;
}
a{
  text-decoration: none;
}
.flow_skincheck_1 {
    background-color: #fff;
    font-size: 14px;
}
.flow_skincheck_1_container {
    padding: 30px 0 40px 0;
}
.progressBar {
    text-align: right;
    padding-bottom: 70px;
}
progress {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #EEE;
    border-radius: 100vh;
    width: 200px;
    height: 10px;
}
.question p {
    font-size: 24px;
    font-weight: bold;
    padding-right: 10px;
}
h1 {
    font-size: 20px;
}
.question{
  display: flex;
  padding-bottom: 10px;
}
/* コンシェルジュ選択ボタン */

.concierge_select_container{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 0;
}
.concierge_select_btn{
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
}
.concierge_select_btn_img{
  border-radius: 50%;
  border: solid 1px #CCC;
  width: 60px;
}
.selected_concierge_icon{
  width: 75px;
  border: solid 2px #F29F8F;
}
.selected_concierge_name{
  font-weight: bold;
}
.concierge_select_btn p{
  font-size: 14px;
  padding-top: 5px;
}
.concierge_img_container {
    position: relative;
    padding: 30px 0 10px;
}
.concierge_img {
    width: 100%;
    object-fit: cover;
}
.concierge_profile {
    padding: 10px 0 20px 0;
}
.profile_btn_container {
    text-align: center;
    padding-bottom: 20px;
}
.profile_btn {
    color: #777;
    background-color: #FADAD2;
    padding: 11px 21px;
    font-size: 14px;
    display: inline-block;
}
.selectConcierge_btn {
    text-align: center;
    font-size: 20px;
    display: block;
    width: 100%;
    border-radius: 100vh;
    padding: 15px 0;
    background-color: #F29F8F;
    color: #fff;
    margin-bottom: 20px;
}
.back_btn {
    text-align: center;
}
.back_btn a {
    padding: 16px 35px;
    border: #F29F8F solid 1px;
    display: inline-block;
}

.fade-enter-active{
  transition: opacity 0.3s ease;
}

.fade-enter-from{
  /* display: none; */
  opacity: 0;
}
.fade-leave-from{
  display: none;
}

/* モーダル部分 */
.concierge_modal p {
  font-size: 20px;
  padding: 7px 11px;
  border: #777 solid 1px;
  display: inline-block;
  z-index: 10;
  box-shadow: 10px 10px #fadad2;
  font-weight: bold;
}
.howToMakeup_container iframe {
  padding: 29px 0 94px 0;
  border: none;
}
/* モーダル部分 */
.concierge_modal {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  z-index: 95;
  overflow: scroll;
}
.concierge_modal_container {
  position: relative;
  background-color: #f5eae7;
  padding: 0 40px;
  overflow: scroll;
}
.concierge_modal_inner{
  padding: 25px 0 15px 0;
}
.concierge_modal_close {
  float: right;
  display: block;
  cursor: pointer;
  width: 80px;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
}
.concierge_modal_close_container {
  position: relative;
  width: 40px;
}
.concierge_modal_close_border_top {
  background-color: #777;
  display: block;
  height: 2px;
  right: -20px;
  width: 100%;
  position: absolute;
  top:-6px;
  transform: translateY(6px) rotate(-20deg);
}
.concierge_modal_close_border_bottom {
  background-color: #777;
  display: block;
  height: 2px;
  right: -20px;
  width: 100%;
  position: absolute;
  bottom: -8px;
  transform: translateY(-6px) rotate(20deg);
}
.concierge_modal_inner h3{
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding: 20px;
}
.modal_faceIcon{
  border-radius: 50%;
  width: 180px;
  display: block;
  margin: 0 auto;
}
.profile_content_container{
  background-color: #fff;
  padding: 20px 10px;
  font-size: 16px;
  line-height: 1.5;
}
.profile_content_container >>> .profile_content p{
  padding-bottom: 10px;
}
</style>
