<script>
import liff from '@line/liff'
import axios from 'axios'
import SkinCheckConfirm from '@/components/flow/SkinCheckConfirm.vue'
import ConciergeChoice from '@/components/flow/ConciergeChoice.vue'
import SkinCondition from '@/components/flow/SkinCondition.vue'
import FaceShape from '@/components/flow/FaceShape.vue'
import FacePartsPlacement from '@/components/flow/FacePartsPlacement.vue'
import FaceForehead from '@/components/flow/FaceForehead.vue'
import FaceChiseled from '@/components/flow/FaceChiseled.vue'
import FaceChin from '@/components/flow/FaceChin.vue'

export default {
  name: 'Question',
  components:{
    ConciergeChoice,
    SkinCheckConfirm,
    SkinCondition,
    FaceShape,
    FacePartsPlacement,
    FaceForehead,
    FaceChiseled,
    FaceChin
  },
  props: {
    series: {
      type: String,
      default: ''
    }
  },
  data(){
    return {
      q: [],
      current_question_index: 0,
      user_answer: {},
      latest_skin_type_id: 0,
      is_first_diag: false
    }
  },
  computed:{
    has_previous_question(){
      return this.current_question_index>1;
    },
    has_next_question(){
      return this.q.length >= this.current_question_index+1;
    },
  },
  created(){
    this.getNextQuestion(true);
    window.addEventListener('popstate', this.popstateEventAction );
  },
  methods: {
    back_question(){
      if(this.q.length>1){
        const del_q = this.q.pop();
        const del_q_number = del_q.questionNumber
        if(del_q_number in this.user_answer){
          delete this.user_answer[del_q_number]
        }
        this.current_question_index--;
      }
      window.scrollTo({
        top: 0
      });
    },
    getNextQuestion: function(is_start, q_number, a_number, finished, nextNumber){
      let id_token = is_start || finished ? liff.getIDToken() : "";
      // ConciergeChoice のみ、データを入れる処理を追加する
      if (q_number === 2) {
        id_token = liff.getIDToken();
      }
      if(q_number>0)this.user_answer[q_number] = {'answer': a_number, 'timestamp': Date.now()};
      if(finished){
        if (this.series == 'item-suggestions'){
          if(this.user_answer[2]['answer']==1){
            this.$router.push({
              name: 'SkincareItemResult',
              state: {
                id_token: id_token,
                result_set: JSON.stringify(this.user_answer)
              }
            });
          }else{
            this.$router.push({
              name: 'MakeupItemResult',
              state: {
                id_token: id_token,
                result_set: JSON.stringify(this.user_answer)
              }
            });
          }
        }else{
          console.log(this.user_answer)
          this.$router.push({
            name: 'SkinCheckResult',
            state: {
              skin_type_id: nextNumber,
              id_token: id_token,
              result_set: JSON.stringify(this.user_answer)
            }
          });
        }
        return;
      }
      const target_url = process.env.VUE_APP_FLOW_URL+this.series;
      axios.post(target_url, {
        liff_id: process.env.VUE_APP_LIFF_ID,
        command: this.q.length==0?'start_flow':'next_question',
        id_token: id_token,
        question_number: q_number,
        answer_number: a_number,
        user_answer:
        this.user_answer
      })
        .then(res=>{
          if('latest_skin_type_id' in res.data){
            this.latest_skin_type_id = res.data.latest_skin_type_id;
          }
          if(is_start && this.series=="skin-type-diag" && res.data.is_first_diag){
            this.is_first_diag = res.data.is_first_diag
            this.$gtag.event("tutorial_begin")
          }
          this.q.push(res.data.question);
          this.current_question_index++;
        })
        .catch(e=>{
          console.log('Question',e);
        });
      window.scrollTo({
        top: 0
      });
      return;
    }
  }
}
</script>

<template>
  <section>
    <section
      v-if="!('component' in q[q.length-1])"
      class="flow_skincheck_1"
    >
      <div class="question">
        <p>Q</p>
        <!-- use v-if to show the profile by language -->
        <h1>
          {{ q[q.length-1]['language'][$i18n.locale]['questionTitle'] }}
        </h1>
      </div>
      <div class="answer_btn_container">
        <!-- use v-if to show the profile by language -->
        <div
          v-for="ans in q[q.length-1]['language'][$i18n.locale]['Answers']"
          :key="ans.enum"
          class="answer_btn"
        >
          <a
            @click="getNextQuestion(
              false,
              q[q.length-1]['questionNumber'],
              ans.answerNumber,
              ans.finished,
              ans.nextNumber
            )"
          >
            {{ ans.answer }}
          </a>
        </div>
      </div>
    </section>
    <section
      v-if="q[q.length-1]['is_flex']"
      class="flow_skincheck_1"
      v-html="q[q.length-1]['html']"
    />
    <skin-check-confirm
      v-if="q[q.length-1]['component']=='skin-type-confirm'"
      :latest_skin_type_id="latest_skin_type_id"
      @catchMessage="getNextQuestion"
    />
    <concierge-choice
      v-if="q[q.length-1]['component']=='concierge-choice'"
      @catchMessage="getNextQuestion"
    />
    <skin-condition
      v-if="q[q.length-1]['component']=='skin-condition'"
      @catchMessage="getNextQuestion"
    />
    <face-shape
      v-if="q[q.length-1]['component']=='face-shape'"
      @catchMessage="getNextQuestion"
    />
    <face-parts-placement
      v-if="q[q.length-1]['component']=='face-parts-placement'"
      @catchMessage="getNextQuestion"
    />
    <face-forehead
      v-if="q[q.length-1]['component']=='face-forehead'"
      @catchMessage="getNextQuestion"
    />
    <face-chiseled
      v-if="q[q.length-1]['component']=='face-chiseled'"
      @catchMessage="getNextQuestion"
    />
    <face-chin
      v-if="q[q.length-1]['component']=='face-chin'"
      @catchMessage="getNextQuestion"
    />
    <div class="back_btn_container">
      <button
        v-if="has_previous_question"
        class="back_btn" 
        @click="back_question"
      >
        <img
          src="@/assets/logos/back_arrow.svg"
        >
        <span>{{ $t('questionBackButton') }}</span>
      </button>
    </div>
  </section>
</template>

<style scoped>
.flow_skincheck_1 {
    background-color: #fff;
    font-size: 14px;
    padding: 0 40px;
}
.flow_skincheck_1_container {
    padding: 30px 0 40px 0;
}
progress {
    -webkit-appearance: none;
    background-color: #EEE;
    border-radius: 100vh;
    width: 200px;
    height: 10px;
    vertical-align: baseline;
}
::-webkit-progress-bar {
    background-color: #777;
}
::-webkit-progress-value {
    background-color: #F29F8F;
}
.progressBar {
    text-align: right;
    padding-bottom: 70px;
}
.question {
    padding: 70px 0 35px 0;
    display: flex;
}
.question p {
    font-size: 24px;
    font-weight: bold;
    padding-right: 10px;
}
h1 {
    font-size: 20px;
    white-space: pre-line;
}
.answer_btn_container {
    padding-bottom: 40px;
    max-width: 95%;
    margin: 0 auto;
}
.answer_btn {
    text-align: center;
    padding-bottom: 20px;
}
.answer_btn a {
    display: block;
    font-size: 18px;
    padding: 16px 25px;
    border-radius: 100vh;
    border: #777 solid 1px;
}
.back_btn_container{
  text-align: center;
  margin-bottom: 50px;
}
.back_btn{
  display: inline-block;
  padding: 16px 35px;
  border: #F29F8F solid 1px;
}
.back_btn img{
  width: 23px;
  display: inline-block;
  height: 12px;
  margin-right: 10px;
}
.back_btn span{
  width: 14px;
}
.concierge_img_container {
    position: relative;
    padding: 50px 0 10px;
}
.concierge_profile {
    padding: 10px 0 20px 0;
}
.profile_btn_container {
    text-align: center;
    padding-bottom: 30px;
}
.profile_btn {
    color: #777;
    background-color: #FADAD2;
    padding: 11px 21px;
    font-size: 14px;
    display: inline-block;
}
.concierge_img {
    width: 100%;
    object-fit: cover;
}
.right_btn {
    position: absolute;
    height: 23px;
    top: 50%;
    right: 0;
}
.selectConcierge_btn {
    text-align: center;
    font-size: 22px;
    display: block;
    width: 100%;
    border-radius: 100vh;
    padding: 15px 0;
    background-color: #F29F8F;
    color: #fff;
    margin-bottom: 90px;
}
img, embed, object, iframe {
    vertical-align: bottom;
}
.progressBar {
    text-align: right;
    padding-bottom: 70px;
}
progress {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #EEE;
    border-radius: 100vh;
    width: 200px;
    height: 10px;
    vertical-align: baseline;
}
</style>
