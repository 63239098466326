<script>
export default {
  name: 'SkinCondition',
  methods:{
    next: function(is_start, q_number, a_number, finished, next_number){
          this.$emit('catchMessage', is_start, q_number, a_number, finished, next_number);
    }
  }
}
</script>

<template>
  <section class="flow_skincheck_1">
    <div class="flow_skincheck_1_container">
      <div class="question">
        <p>Q</p>
        <h1>{{ $t('skinConditionQuestion') }}</h1>
      </div>
      <div class="answer_btn_container">
        <div class="answer_btn">
          <a @click="next(false, 50, 0, false, 51)">
            {{ $t('skinConditionAnswer1') }}
          </a>
        </div>
        <div class="answer_btn">
          <a @click="next(false, 50, 1, false, 51)">
            {{ $t('skinConditionAnswer2') }}
          </a>
        </div>
        <div class="answer_btn">
          <a @click="next(false, 50, 2, false, 51)">
            {{ $t('skinConditionAnswer3') }}
          </a>
        </div>
        <div class="answer_btn">
          <a @click="next(false, 50, 3, false, 51)">
            {{ $t('skinConditionAnswer4') }}
          </a>
        </div>
        <p>{{ $t('skinConditionFootnote') }}</p>
      </div>
    </div>
  </section>
</template>

<style scoped>
.flow_skincheck_1 {
    background-color: #fff;
    font-size: 14px;
}
.flow_skincheck_1_container {
    padding: 30px 0 40px 0;
}
.question {
    padding-bottom: 35px;
    display: flex;
}
.question p {
    font-size: 24px;
    font-weight: bold;
    padding-right: 10px;
}
h1 {
    font-size: 20px;
}
.answer_btn_container {
    max-width: 95%;
    margin: 0 auto;
}
.answer_btn {
    text-align: center;
    padding-bottom: 20px;
}
.answer_btn a {
    display: block;
    font-size: 18px;
    padding: 16px 25px;
    border-radius: 100vh;
    border: #777 solid 1px;
}
.answer_btn_container p {
    padding-bottom: 20px;
}
</style>
